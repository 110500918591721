/* .App {
  text-align: center;
} */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* @import url("https://fonts.cdnfonts.com/css/nordic"); */

/* @font-face {
  font-family: "Nordic";
  src: url("./assets/font/Nordic/Nordic-Sans.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Nordic";
  src: url("./assets/font/Nordic/Nordic-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 400;
} */

body {
  /* background-color: #fcfcfc !important; */
  background-image: linear-gradient(150deg, #2c0e34 5%, #07081b 44%) !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  font-family: "Poppins" !important;
}
:root {
  --secondary-color: #d92aab; /* Default color */
}
.app-secondary-color {
  color: var(--secondary-color) !important;
}
.ant-modal-content {
  background: #24264c !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px !important;
}
/* .anticon svg {
  color: #fff !important;
} */
.aivolvex-font {
  font-family: "Poppins" !important;
}
.text-center {
  text-align: center;
}
.aivolvex-page-title {
  font-size: 20px;
  line-height: 45.92px;
}
.right-padding-2 {
  padding-right: 2rem;
}
.btn-primary-new {
  font-size: 17px;
  background: #db2aac;
  padding: 0.4rem 2rem;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #db2aac !important;
  color: #fff !important;
}
.logo-peta-login {
  color: #db2aac;
  font-size: 15px;
  position: absolute;
  top: 11%;
}
.white-color {
  color: #fff !important;
}
.site-second-color {
  color: #db2aac !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.body-margin-top {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}
.right-token-view {
  position: absolute;
  right: 5%;
  top: 37%;
}
/* .body-bg {
  background-color: #fcfcfc !important;
} */
.cursor-pointer {
  cursor: pointer !important;
}
img.navlogo.cursor-pointer {
  height: 42px;
  width: 142px;
}

.ant-popover-title {
  color: #fff !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.recent-table.ant-table > tr > th {
  background-color: #f0f2f5 !important;
  color: red !important;
  font-weight: bold;
}

.window-margin-right {
  margin-right: 15px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .recent-table thead th {

  background: var(blue) !important;

  color: red !important;

  border: none !important;

  font-weight: 500 !important;

  font-size: 14px !important;

  line-height: 100% !important;

  white-space: nowrap;
  z-index: 0 !important;

}

.costimize-savings-table-footer {

  background: var(blue) !important;

  color: #fff !important;

  font-weight: 600 !important;

  font-size: 14px !important;

  line-height: 100% !important;

  border: none !important;

  text-align: center !important;

}

.costimize-savings-table-footer

  .ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {

  background: var(blue) !important;

}

.costimize-savings-table-footer

  .ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {

  background: var(blue) !important;

}

.recent-table thead th .order > .dropup > .caret {

  margin: 10px 0;

  color: #fff;

  display: inline-block;

  width: 0;

  height: 0;

  margin-left: 2px;

  vertical-align: middle;

  border-top: 4px dashed;

  border-top: 4px solid\9;

  border-right: 4px solid transparent;

  border-left: 4px solid transparent;

}

.recent-table thead th .order > .dropdown > .caret {

  margin: 10px 0 10px 5px;

  color: #fff;

  display: inline-block;

  width: 0;

  height: 0;

  margin-left: 2px;

  vertical-align: middle;

  border-right: 4px solid transparent;

  border-left: 4px solid transparent;

  content: "";

  border-top: 0;

  border-bottom: 4px dashed;

}

.recent-table

  thead

  th

  > .react-bootstrap-table-sort-order.dropup

  > .caret {

  margin: 10px 6.5px;

  display: inline-block;

  width: 0;

  height: 0;

  margin-left: 2px;

  vertical-align: middle;

  border-top: 4px dashed;

  border-top: 4px solid;

  border-right: 4px solid transparent;

  border-left: 4px solid transparent;

  border-bottom: none !important;

}

.recent-table

  thead

  th

  > .react-bootstrap-table-sort-order

  > .caret {

  margin: 10px 6.5px;

  display: inline-block;

  width: 0;

  height: 0;

  margin-left: 2px;

  vertical-align: middle;

  border-right: 4px solid transparent;

  border-left: 4px solid transparent;

  content: "";

  border-top: 0;

  border-bottom: 4px dashed;

}

.recent-table thead td {

  background-color: var(blue) !important;

  color: #fff !important;

  border: none !important;

  font-weight: 500 !important;

  font-size: 14px !important;

  line-height: 100% !important;

  white-space: nowrap;

  z-index: 0 !important;

}

.recent-table thead >tr>td {    position: relative;
color: #fff !important;
font-weight: 600;
text-align: center;
background: rgba(0, 0, 0, 0.88) !important;
transition: background 0.2s ease;
} */

.recent-table .ant-table {
  border-bottom: 1px solid rgba(0, 0, 0, 0.88);
}

.recent-table .ant-table-thead > tr > th {
  color: #fff !important;
  font-weight: 600;
  text-align: center;
  background: rgba(0, 0, 0, 0.88) !important;
  transition: background 0.2s ease;
  border-bottom: transparent;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.recent-table .ant-table-tbody > tr:nth-child(even) {
  background: linear-gradient(to right, #f0f5ff, #e1f5fe);
}

.recent-table .ant-table-filter-trigger {
  color: white;
}
.history-table .ant-table-filter-trigger {
  color: white;
}

.history-table .ant-table {
  border-bottom: 1px solid rgba(0, 0, 0, 0.88);
}

.history-table .ant-table-thead > tr > th {
  color: #fff !important;
  font-weight: 600;
  text-align: center;
  background: rgba(0, 0, 0, 0.88) !important;
  transition: background 0.2s ease;
  border-bottom: transparent;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.history-table .ant-table-tbody > tr:nth-child(even) {
  background: linear-gradient(to right, #f0f5ff, #e1f5fe);
}

.logo-peta {
  color: #db2aac;
  font-size: 12px;
  position: absolute;
  top: 22%;
}
.font-size-17 {
  font-size: 15px;
}
/* model  */
.ant-modal-title {
  background-color: #24264c;
  color: #fff !important;
}
/* end model */
/* drawar style */

.ant-input {
  color: #fff !important;
  background: #24264c;
  border: 0.5px solid #48495e;
}
.ant-input::placeholder {
  color: hsla(0, 0%, 100%, 0.29);
}
.ant-drawer-wrapper-body {
  background: #17193a;
}
.ant-drawer-title {
  color: #fff !important;
}
label.ant-form-item-required {
  color: #fff !important;
}
.onboard_avatar_updated {
  color: #fff;
}
.ant-form-item-label label {
  color: #fff !important;
}
.ant-upload.ant-upload-select {
  color: #fff;
}
.aivolvex-custom-select {
  background-color: #17193a;
}
.aivolvex-custom-select .ant-select-selector {
  background: #24264c !important;
  border: 1px solid #4a4747 !important;
}
span.ant-select-selection-placeholder {
  color: #757575 !important;
}
.ant-select-dropdown {
  background-color: #24264c !important;
}
.ant-select-item-option-content {
  color: #fff !important;
}
.ant-select-selection-item {
  color: #aaaaaa;
}
.ant-select-item-option-active {
  background-color: #17193a !important;
}
.ant-select-item-option-selected {
  background: #24264c !important;
}
.aivolvex-ant-upload {
  color: #fff;
  margin-bottom: 2%;
}
.ant-upload-list-item-name {
  color: #fff !important;
}
span.anticon.anticon-ellipsis {
  font-size: 32px;
}
.ant-modal-body {
  color: #fff !important;
}
/* end drawar */

@media only screen and (max-width: 2560px) and (min-width: 2305px) {
}
@media only screen and (max-width: 2304px) and (min-width: 2161px) {
}
@media only screen and (max-width: 2160px) and (min-width: 1922px) {
}
@media only screen and (max-width: 1921px) and (min-width: 1681px) {
}
@media only screen and (max-width: 1680px) and (min-width: 1601px) {
}
@media only screen and (max-width: 1600px) and (min-width: 1471px) {
}
@media only screen and (max-width: 1470px) and (min-width: 1441px) {
}
@media only screen and (max-width: 1440px) and (min-width: 1367px) {
}
@media only screen and (max-width: 1366px) and (min-width: 1281px) {
}
@media only screen and (max-width: 1280px) and (min-width: 1081px) {
}
@media only screen and (max-width: 1080px) and (min-width: 1025px) {
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
}
@media only screen and (max-width: 768px) and (min-width: 427px) {
}
@media only screen and (max-width: 426px) and (min-width: 377px) {
}
@media only screen and (max-width: 376px) and (min-width: 321px) {
}
@media (max-width: 320px) {
}
