.signInCarousel1Image,
.signInCarousel3Image,
.signInCarousel2Image {
  height: 100% !important;
  width: 100% !important;
}

.signInCarousel2Image {
  width: 32rem;
}

.signInCarousel3Image {
  width: 25rem;
}

.splide__pagination__page {
  background: #f6ecec;
  border: 0;
  // border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 5rem;
}

.splide__pagination__page {
  background: #f6ecec;
  border: 0;
  /* border-radius: 50%; */
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 5rem;
}

.splide__pagination__page.is-active {
  background: linear-gradient(90deg, #f33a56 -1.19%, #6e039f 164.88%);
  transform: scale(1.4);
  z-index: 1;
}
