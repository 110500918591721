.aivolvex-add-btn {
  background-color: #db2aac !important;
  font-size: 14px;
  font-weight: 500;
}
/* start popconfirm */
.ant-popover-inner {
  background: #17193a !important;
}
.ant-popconfirm-title {
  color: #fff !important;
}
.ant-popconfirm-description {
  color: #fff !important;
}
button.ant-btn.ant-btn-primary.ant-btn-sm {
  background: #db2aac !important;
}
.ant-popover .ant-popover-arrow::before {
  background: #17193a !important;
}
/* end popconfirm */
/* start popup select box */
.ant-select-selector {
  background: #24264c !important;
}
/* end popup select box */


.tag-data{
  /* width: 300px; */
  flex:4;
}
.tag-icon{
  float: right;
  flex:2;
}
.tag-container{
  display: flex;
}
.tag-btn{
  flex:2;

}

.tag-list-card{
  /* display: flex;
  justify-content:space-around; */
  border: none;
  background-color: transparent;
  color:#fff;
  /* box-shadow: -1px 4px 4px rgba(217, 217, 217, 0.6) !important; */
  box-shadow: -1px 4px 4px rgba(217, 217, 217, 0.26) !important;
  margin-bottom: 4px;
  max-height: 50px;

}
.tag-list-card.ant-card .ant-card-body {
  padding: 8px !important;

}
.icon-with-badge {
  position: relative;
  display: inline-block;
  margin-left: 8px;
}

.badge {
  position: absolute;
  /* top: -8px; 
  right: -24px;  */
  top: -8px; 
  right: -11px; 
  background-color: black;
  /* background-color:linear-gradient(150deg, #2c0e34 5%, #07081b 44%) !important; */
  /* background-color: #17193a; */
  color: white; 
  border-radius: 50%; 
  padding: 5px 1px; 
  font-size: 9px;
  width: 1.2rem;
  height: 1.2rem;

}
.tag-card{
  padding-left: 0rem !important;
  
}
.ant-space {

  display: flex;
  justify-content: space-between;
}
